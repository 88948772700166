import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from "@angular/core";
import { Subscription, Observable, OperatorFunction, of, debounceTime, distinctUntilChanged, filter, map, tap, switchMap, catchError } from "rxjs";
import { UntypedFormGroup, FormControl, FormGroupDirective } from "@angular/forms";
import { LoadingHelper } from "@shared/helpers/loading.helper";
import { ApplicationError } from "@core/models/application-error.model";
import { QueryResult } from "@domain/models/query-result.model";

@Component({
  template: ''
})
export abstract class TypeAheadSearchComponent<T> implements OnInit, OnDestroy {
  @Input() clearSearchOnSelectedMatch = false;

  @Output() selected = new EventEmitter<T>();
  @Output() searchErrorReceived = new EventEmitter<string>();
  @Output() loadingChanged = new EventEmitter<boolean>();

  private _loadingHelper = new LoadingHelper();
  private _componentSubscriptions = new Array<Subscription>();

  private _formGroup: UntypedFormGroup;
  private _searchFormControl: FormControl<T>;

  private _searchEmpty: boolean;
  private _searching = false;
  private _searchFailed = false;

  private _totalCount = null;
  private _resultCount = null;
  private _matches: Array<T> = null;

  constructor(private parentFormGroup: FormGroupDirective = null) {
    this._componentSubscriptions.push(this._loadingHelper.loadingChanged.subscribe((value) => {
      this.emitLoadingChanged(value);
    }));
  }

  ngOnInit() {
    this._searchFormControl = new FormControl<T>(this.initialSelection);

    this._formGroup = new UntypedFormGroup({
      search: this._searchFormControl
    });

    if (this.parentFormGroup != null) {
      this.parentFormGroup.control.addControl("formGroup", this._formGroup);
    }

    this._componentSubscriptions.push(this._searchFormControl.valueChanges
      .subscribe((match: T) => {
        if (match != null && this.isInstanceOfType(match)) {
          this.emitSelected(match);
          if (this.clearSearchOnSelectedMatch) {
            this.clear();
          }
        }
        else {
          this.emitSelected(null);
        }
      }));
  }

  ngOnDestroy(): void {
    this._componentSubscriptions.forEach(s => {
      s.unsubscribe();
    });
    this._componentSubscriptions.splice(0);
  }

  public get formGroup() {
    return this._formGroup;
  }

  public get searching() {
    return this._searching;
  }

  public get isLoading(): boolean {
    return this._loadingHelper.isLoading;
  }

  public get searchFailed(): boolean {
    return this._searchFailed;
  }

  public get searchEmpty(): boolean {
    return this._searchEmpty;
  }

  public get selectedMatch(): T {
    return this._searchFormControl.value;
  }

  public set selectedMatch(value: T) {
    this._searchFormControl.setValue(value);
  }

  public search: OperatorFunction<string, readonly T[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => {
        this._searchEmpty = term.toString().length >= this.minSearchTermLength;
        return term.toString().length >= this.minSearchTermLength;
      }),
      tap(() => {
        this._searching = true;
        this._loadingHelper.startLoading();
      }),
      switchMap(term => {
        return this.searchAction(term).pipe(
          map(data => {
            this._totalCount = data.total;
            this._resultCount = data.result.length;
            this._matches = data.result;
            return data.result;
          }),
          tap(() => {
            this._searchFailed = false;
            this._searchEmpty = true;
          }),
          catchError((error) => {
            this._searchFailed = true;
            this.emitSearchErrorReceived(ApplicationError.getMessage(error));
            return of([]);
          }));
      }),
      tap(() => {
        this._searching = false;
        this._loadingHelper.reset();
      })
    );

  public inputFormatter = (match: T): string => {
    if (!match) {
      return "";
    }

    this._searchEmpty = false;

    return this.formattedAsText(match);
  };

  public clear() {
    this._searchFormControl.setValue(null);
    this._searching = false;
    this._searchFailed = false;
    this._searchEmpty = false;
  }

  public isLastItemInList(match: T) {
    var lastPart = this._matches[this._matches.length - 1];
    return lastPart == match;
  }

  public get showRefineSearch(): boolean {
    return this._totalCount > this._resultCount;
  }

  public get resultText() {
    return this.formattedResultText(this._resultCount, this._totalCount);
  }

  public get searchInstruction() {
    return this.formattedSearchInstruction(this.minSearchTermLength);
  }

  public abstract get placeholderText(): string;

  public abstract get minSearchTermLength(): number;

  protected abstract formattedAsText(match: T): string;

  protected abstract formattedResultText(resultCount: number, totalCount: number): string;

  protected abstract formattedSearchInstruction(minSearchTermLength: number): string;

  protected abstract searchAction(term: string): Observable<QueryResult<T>>;

  protected abstract isInstanceOfType(value: T): boolean;

  protected abstract strikeThroughText(match: T): boolean;

  protected abstract get initialSelection(): T;

  private emitLoadingChanged(loading: boolean) {
    this.loadingChanged.emit(loading);
  }

  private emitSearchErrorReceived(errorMessage: string) {
    this.searchErrorReceived.emit(errorMessage);
  }

  private emitSelected(match: T) {
    this.selected.emit(match);
  }
}
