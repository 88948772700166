<section class="o-page-wrap o-page-wrap--xsmall mt-5 mb-5">
  <div class="c-card p-4" [class.spinner-wrapper]="isLoading">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <h3 class="u-mb-xsmall">{{ userId ? 'Redigera' : 'Skapa användare' }}</h3>
    <hr class="mb-4">
    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <fieldset class="o-fieldset u-mb">
        <div *ngIf="isLockedOut" class="alert alert-warning">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-middle">
              <i aria-hidden="true" class="c-icon c-icon--small c-icon--[lock] me-2"></i>
              <label class="m-0">Användaren är låst för inloggning</label>
            </div>
            <button class="c-btn c-btn--secondary" (click)="onUnlock($event)">
                <span class="c-btn__text">Lås upp</span>
            </button>             
          </div>
        </div>
        <div class="o-fieldset__row mb-4 c-card bg-grey">
          <div class="d-flex justify-content-between">
            <h5>{{ user ? user.firstName : ''}} {{ user ? user.lastName : ''}}</h5>
            <h5>{{ user ? user.socialSecurityNumber : ''}}</h5>
          </div>
          <div>
            <span><b>Roller: </b>{{ user ? user.businessSystemRoles : '-'}} </span>
          </div>
          <div>
            <span><b>Kundnummer: </b>{{ user ? user.fleetCustomerNumber : '-'}} </span>
          </div>         
          <div>
            <span><b>Mobilnummer: </b>{{ user ? user.fleetContactMobileNumber : '-'}} </span>
          </div>
          <div>
            <span><b>E-postadress: </b>{{ user ? user.fleetContactEmailAddress : '-'}} </span>
          </div>
          <div>
            <span><b>Anställningsnummer: </b>{{ user?.employeeId ? user.employeeId : '-'}} </span>
          </div>
          <div class="mt-2" *ngIf="!isNewUser">
            <span><b>Skapad: </b>{{ user ? (user.createdAtUtc | date: 'yyyy-MM-dd HH:mm:ss') : '-'}}</span>
          </div>
          <div *ngIf="!isNewUser">
            <span><b>Senast inloggad: </b>{{ user ? (user.lastLoginDate | date: 'yyyy-MM-dd HH:mm:ss') : '-'}}</span>
          </div>
        </div>
        <div class="o-fieldset__row mb-2">
          <label for="newUserInput">Användarnamn (e-postadress)*</label>
          <div class="c-input mb-1">
            <input id="newUserInput" formControlName="userName" class="c-input__input" type="text" maxlength="255" [class.is-error]="emailInvalid" />
          </div>
          <small *ngIf="emailRequiredInvalid" class="is-error">
            Användarnamn måste anges.
          </small>
          <small *ngIf="emailPatternInvalid" class="is-error">
            Användarnamn är inte en giltig e-postadress.
          </small>
        </div>        
        <div *ngIf="emailDifferentFromContactAddress" class="alert alert-warning">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-middle">
              <label class="m-0">Användarnamnet skiljer sig från e-postadressen i Fleet kontaktadress.</label>
            </div>
          </div>
        </div>
        <div class="o-fieldset__row mb-2 mt-4">
          <label>Behörighet till FleetWeb*</label>
          <div formArrayName="roles" *ngFor="let check of rolesFormArray.controls; let i = index" class="switch mb-2 mt-2">
            <input type="checkbox" class="switch" [id]="rolesDescription[i].value" [formControl]="check" />
            <label [for]="rolesDescription[i].value">{{ rolesDescription[i].description }}</label>
          </div>
          <small *ngIf="rolesInvalid" class="is-error">
            Minst en roll måste väljas.
          </small>
        </div>       
        <div *ngIf="!limited" class="o-fieldset__row mt-4 mb-2">
          <label>Ramavtal*</label>
          <app-frame-agreement-selector formControlName="frameAgreement"></app-frame-agreement-selector>
          <small *ngIf="frameAgreementInvalid" class="is-error">
            Ett ramavtal måste väljas.
          </small>         
          <div *ngIf="frameAgreementDiffersFromBusinessSystem" class="alert alert-warning mt-2">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-middle">
                <label class="m-0">Användarens ramavtal skiljer sig från personens ramavtal i affärssystemet.</label>
              </div>
            </div>
          </div>
        </div>      
        <div class="o-fieldset__row mb-2 mt-4" *ngIf="hasElevatedRole && frameAgreementId != null">
          <label>Behörig till kunder*</label>
          <app-customers-selector [frameAgreementId]="frameAgreementId" formControlName="customers"></app-customers-selector>
          <small class="is-error" *ngIf="customersInvalid">
            Minst en kund måste väljas.
          </small>
        </div>      
        <div class="mt-4">
          <label>
            <em class="vertical-align-middle">* Obligatoriska uppgifter</em>
          </label>
        </div>       
      </fieldset>
      <div class="d-flex mt-auto justify-content-between">
        <a routerLink="../../" class="c-btn c-btn--secondary ">
          <span class="c-btn__text">Avbryt</span>
        </a>        
        <button *ngIf="!isNewUser" type="submit" class="c-btn" [disabled]="submitDisabled">
            <span class="c-btn__text">Spara</span>
        </button>        
        <button *ngIf="isNewUser" type="submit" class="c-btn">
          <span class="c-btn__text">Skapa</span>
        </button>  
      </div>
    </form>
  </div>
</section>
