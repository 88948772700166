import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NotAuthorizedComponent } from './core/components/not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: "Logga in"
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      breadcrumb: "Återställ lösenord"
    }
  },
  {
    path: 'cars',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/cars/cars.module').then(m => m.CarsModule)
  },
  {
    path: 'company',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'support-cases',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import("./features/support-cases/support-cases.module").then(m => m.SupportCasesModule)
  },
  {
    path: 'super-user',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/super-user/super-user.module').then(m => m.SuperUserModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "**",
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
