import { Component, Input } from '@angular/core';
import { CarDetailModel } from '@domain/models/car-detail.model';
import { ServiceLevel } from '@core/models/service-level.enum';
import { LoggingService } from '@core/services/logging.service';

@Component({
  selector: 'app-car-detail-card',
  templateUrl: './car-detail-card.component.html',
  styleUrls: ['./car-detail-card.component.scss']
})
export class CarDetailCardComponent {

  public ServiceLevel = ServiceLevel;
  @Input() car: CarDetailModel;
  @Input() showDriver: boolean = false;
  @Input() showAffiliatesLink: boolean = true;
  
  displayExtraContent: boolean;
  displayFuelCard: boolean;

  constructor(
    private _loggingService: LoggingService) {    
    
  }

  toggleExtraContent() {
    this.displayExtraContent = !this.displayExtraContent;
  }

  toggleFuelCard() {
    this.displayFuelCard = !this.displayFuelCard;
    this._loggingService.logDebug("this.car.fuelCards:", this.car.fuelCards);
  }

  hasMileageReporting() {
    return this.hasServiceLevel(ServiceLevel.Bränsleförmånsrapportering);
  }

  hasAllowSplit(): boolean {
    if (this.car)
      return this.car.allowSplit;
    return false;
  }

  private hasServiceLevel(serviceLevel: number): boolean {
    return this.car.serviceLevels.includes(serviceLevel);
  }
}
