import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'kilometer'
})
export class KilometerPipe extends DecimalPipe implements PipeTransform {

    override transform(value: null | number): null;
    
    override transform(value: string | number): string | null {
        if(value == null)
            return "";

        return super.transform(value) + " km";
    }
}