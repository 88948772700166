import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarService } from '@domain/services/car.service';
import { CarDetailModel } from '@domain/models/car-detail.model';
import { CarAndDriverQuery } from '../../../../domain/models/filters/car-and-driver-query-model';
import { LoadingHelper } from '@shared/helpers/loading.helper';
import { ApplicationError } from '@core/models/application-error.model';

@Component({
  selector: 'app-car-info-modal',
  templateUrl: './car-info-modal.component.html',
  styleUrls: ['./car-info-modal.component.scss']
})
export class CarInfoModalComponent implements OnInit {
  @Input() carAndDriverQuery: CarAndDriverQuery;
  @Input() objectId: number;
  @Input() license: string;
  @Input() accountNumber: number;
  @Input() driverId: number;
  @Input() includeInactive: boolean;
  
  errorMessage: string;
  car: CarDetailModel;
  displayExtraContent: boolean;
  displayFuelCard: boolean;

  private _loadingHelper = new LoadingHelper();

  constructor(
    public modal: NgbActiveModal, 
    private _carService: CarService) { }

  ngOnInit() {
    this.getCar();
  }

  get loading() {
    return this._loadingHelper.isLoading;
  }

  closeModal() {
    this.modal.close();
  }

  private getCar() {
    this._loadingHelper.startLoading();
    this.errorMessage = null;

    (this.carAndDriverQuery !== null
      ? this._carService.getCarDetail(this.carAndDriverQuery)
      : this._carService.getCarDetail(this.license, this.accountNumber)).subscribe({
      next: data => {
        this.car = data;
      },
      error: error => {
        this._loadingHelper.stopLoading();
        this.errorMessage = ApplicationError.getMessage(error);        
      },
      complete: () => {
        this._loadingHelper.stopLoading();
      }
    });
  }
}
