import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, CurrencyPipe, DatePipe } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

registerLocaleData(localeSv);

import { AppRoutingModule } from './app-routing.module';

// Internal modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { DomainModule } from './domain/domain.module';

import { AppComponent } from './app.component';
import { KilometerPipe } from './shared/pipes/kilometer.pipe';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { CustomDateAdapter } from '@shared/helpers/custom-date-adapter';
import { DateAdapter } from '@angular/material/core';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    NgbModule,

    // Internal modules
    SharedModule,
    CoreModule.forRoot(),
    DomainModule.forRoot(),

    // App routing should be last in list
    AppRoutingModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "sv" },
    KilometerPipe,
    CurrencyPipe,
    DatePipe,
    FilterPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
