import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-common-info-message',
    templateUrl: './common-info-message.component.html',
    styleUrls: ['./common-info-message.component.scss']
})
export class CommonInfoMessageComponent {
    
    @Input() title: string;
    @Input() message: string;
    @Input() error: boolean = false;

    constructor(public modal: NgbActiveModal) { }
}