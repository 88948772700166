import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { DriverService } from "@domain/services/driver.service";
import { DriverPart } from "@domain/models/driver-part.model";
import { FormGroupDirective } from "@angular/forms";
import { SearchDriverQuery } from "@domain/models/filters/search-driver-query.model";
import { QueryResult } from "@domain/models/query-result.model";
import { TypeAheadSearchComponent } from "../type-ahead-search/type-ahead-search.component";

@Component({
  selector: "app-driver-search",
  templateUrl: "../type-ahead-search/type-ahead-search.component.html",
  styleUrls: ["../type-ahead-search/type-ahead-search.component.scss"]
})
export class DriverSearchComponent extends TypeAheadSearchComponent<DriverPart> {
  
  private readonly _minSearchTermLength = 3;
  private readonly _maxNumberOfResults = 15;

  @Input() excludePendingDrivers: boolean;
    
  constructor(private driverService: DriverService,
    parentFormGroup: FormGroupDirective) {
      super(parentFormGroup);
  }
  
  public override get placeholderText(): string {
    return "Sök personnummer, förnamn eller efternamn";
  }

  public override get minSearchTermLength(): number {
    return this._minSearchTermLength;
  }

  protected override formattedAsText(match: DriverPart): string {
    if (!match || match == null) {
      return "";
    }
    return `${match.firstName ? match.firstName.trimEnd() : ""} ${match.lastName ? match.lastName.trimEnd() : "".trimEnd()} ${match.personalNumber ? match.personalNumber.trimEnd() : "".trimEnd()}`;
  }

  protected override formattedResultText(resultCount: number, totalCount: number): string {
    return `Visar ${resultCount} av totalt ${totalCount} hittade förare.`;     
  }

  protected override formattedSearchInstruction(minSearchTermLength: number): string {
    return `Du kan söka på förarens namn med minst ${minSearchTermLength} bokstäver, alt. personnummer med minst 6 siffror (YYMMDD-NNNN).`;
  }

  protected override searchAction(term: string): Observable<QueryResult<DriverPart>> {
    const searchDriverQuery = new SearchDriverQuery();
    searchDriverQuery.searchText = term.toString();
    searchDriverQuery.pageSize = this._maxNumberOfResults;
    searchDriverQuery.excludePendingDrivers = this.excludePendingDrivers;
    
    return this.driverService.searchDrivers(searchDriverQuery);
  }
    
  protected override isInstanceOfType(value: DriverPart): boolean {
    return value instanceof DriverPart;
  }

  protected override strikeThroughText(match: DriverPart): boolean {
    return false;
  }

  
  protected override get initialSelection(): DriverPart {
    return null;
  }
}
