import { Component, Input } from '@angular/core';
import { CarInfoModel } from '../../../domain/models/car-info.model';
import { ServiceLevel } from '@core/models/service-level.enum';
import { AuthenticationService } from '@core/services/authentication.service';
import { Permission } from '@core/models/permissions.enum';

@Component({
    selector: 'app-car-basic-card',
    templateUrl: './car-basic-card.component.html',
    styleUrls: ['./car-basic-card.component.scss']
})
export class CarBasicCardComponent {

    public ServiceLevel = ServiceLevel;
    @Input() car: CarInfoModel;

    constructor(private _authenticationService: AuthenticationService) { }

    get canSeeReportHistory() {
        return this.canReportMileage || this.canReportToll;
    }

    get canReport() {
        return this.canReportMileageNoFuelBenefit || this.canReportMileage || this.canReportToll;
    }

    get canReportMileage(): boolean {
        return this._authenticationService.hasPermission(Permission.KilometerRapportering) && this.car.canReportMileage;
    }

    get canReportMileageNoFuelBenefit(): boolean {
        return this._authenticationService.hasPermission(Permission.MätarställningRapportering) && this.car.serviceLevels.includes(ServiceLevel.Mätarställningsinrapportering);
    }

    get canReportToll(): boolean {
        return this._authenticationService.hasPermission(Permission.TrängselskattRapportering) && this.car.canReportToll;
    }    
}
