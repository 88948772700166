<div *ngIf="readonlyMode" class="d-flex justify-content-between">
    <div>                
        <span [class.line-through]="frameAgreement && !frameAgreement.enabled">{{ frameAgreement != null ? frameAgreement.name + ' ('+ frameAgreement.quotaAccountName +')' : '-'}}</span>        
    </div>            
    <button type="button" class="c-btn c-btn--secondary" (click)="resetFrameAgreement()" >
        <span class="c-btn__text">Ändra</span>
    </button>       
</div>
<div *ngIf="editMode">
    <form *ngIf="formGroup" [formGroup]="formGroup" class="d-flex justify-content-start">
      <div class="c-input mb-3 w-100">
        <app-frame-agreement-search class="w-100"
                                    [includeDisabled]="includeDisabled"                                                
                                    (selected)="onSelected($event)">
        </app-frame-agreement-search>
      </div>
      <div class="switch ms-3 mt-1">
        <input type="checkbox" class="switch" id="switch-inactive" formControlName="includeDisabled">
        <label for="switch-inactive" class="text-nowrap">Visa inaktiva</label>
      </div>
    </form>
</div>   